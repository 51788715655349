import React, { useEffect, useMemo, useState } from 'react'
import {
  getBusiness as getBusinessApi,
  init as initApi,
  logEvent as logEventApi,
  register as registerApi
} from '../api'
import Log from '../log'
import { Business } from '../lib/types'
import Config, { SetConfig } from '../config'

interface State {
  initialized?: boolean
  _goid?: string | null
  visitor_id?: string | null
  business?: Business
  logEvent: (name: string, data: any) => Promise<any>
}

const initialState = {
  business: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logEvent: (name: string, data: any) => Promise.resolve({})
}

type Action =
  | { type: 'SET_GOID'; value?: string | null }
  | { type: 'SET_VISITOR_ID'; value?: string | null }
  | { type: 'SET_BUSINESS'; value?: Business }

export const VisitorContext = React.createContext<State>(initialState)
VisitorContext.displayName = 'VisitorContext'

function visitorReducer(state: State, action: Action) {
  Log(action)
  switch (action.type) {
    case 'SET_GOID': {
      SetConfig({ _goid: action.value })
      return {
        ...state,
        _goid: action.value
      }
    }
    case 'SET_VISITOR_ID': {
      SetConfig({ visitor_id: action.value })
      return {
        ...state,
        visitor_id: action.value,
        initialized: true
      }
    }
    case 'SET_BUSINESS': {
      SetConfig({ business: action.value })
      return {
        ...state,
        business: action.value
      }
    }
  }
}

export const VisitorProvider: React.FC = (props): JSX.Element => {
  const [state, dispatch] = React.useReducer(visitorReducer, initialState)
  const [reconnectingInterval, setReconnectingInterval] =
    useState<NodeJS.Timeout>()
  const [initializing, setInitializing] = useState<boolean>()

  const init = () => {
    const visitor = {
      _referrer: document.referrer,
      _origin: window.location.href,
      _goid: Config._goid
    }

    return initApi(visitor).then(({ ok, data, ...rest }) => {
      ok && dispatch({ type: 'SET_GOID', value: data._goid.toString() })
      return { ok, data, ...rest }
    })
  }

  const register = (_goid: number) => {
    const visitor = { _goid: _goid.toString() }

    return registerApi(visitor).then(({ ok, data, ...rest }) => {
      ok &&
        dispatch({ type: 'SET_VISITOR_ID', value: data.visitor_id.toString() })

      return { ok, data, ...rest }
    })
  }

  const logEvent = (name: string, data: any) => logEventApi({ name, data })

  const getBusiness = (visitor_id: number) =>
    getBusinessApi({ visitor: visitor_id }).then(({ ok, data, ...rest }) => {
      ok && dispatch({ type: 'SET_BUSINESS', value: data })

      return { ok, data, ...rest }
    })

  const memoValue = useMemo(
    () => ({
      ...state,
      logEvent
    }),
    [state]
  )

  useEffect(() => {
    Log(state, 'visitorContext')
  }, [state])

  useEffect(() => {
    if (state.initialized) {
      if (reconnectingInterval) {
        clearInterval(reconnectingInterval)
        setReconnectingInterval(undefined)
      }

      return
    }

    if (!reconnectingInterval) {
      setReconnectingInterval(
        setInterval(() => {
          Log('reconnecting', 'visitor')
          if (!initializing) {
            setInitializing(true)
            init().then(({ ok, data }) => {
              ok &&
                register(data._goid).then(({ ok, data }) => {
                  ok && getBusiness(data.visitor_id)
                  setInitializing(false)
                })
            })
          }
        }, 5000)
      )
    }
  }, [state.initialized])

  return <VisitorContext.Provider value={memoValue} {...props} />
}

export const useVisitor = () => {
  const context = React.useContext(VisitorContext)
  if (context === undefined) {
    throw new Error(`useVisitor must be used within a VisitorProvider`)
  }
  return context
}
